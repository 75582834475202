<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
import db from "@/firebase/db"

export default {
  name: 'HomeView',
  data() {
    return {
      mealplans: [],
    }
  },
  firestore: {
    mealplans: db.collection('meal_plans')
  }
}

</script>
<style>
.drag-handle {
  cursor: move;
	cursor: -webkit-grab;
}
.drag-handle:mousedown {
  cursor: moving;
	cursor: -webkit-grabbing;
}

.drag-ghost {
  background: #c2e9d8;
}
.drag-item {
  background: #eef9f4;
}

.round-rect {
  border: 1px solid #eeeeee;
  border-radius: 10px;
}

</style>
